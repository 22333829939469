import React from 'react'

import {
  List,
  ListItem,
  Paragraph,
  SideStep,
  SideSteps
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SideStepsExample } from '../../examples/react/SideStepsExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SideSteps"
    components={[{ component: SideSteps }, { component: SideStep }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>SideSteps</Code>-navigaation avulla käyttäjä näkee kerralla
        kokonaiskuvan lomakkeen vaiheista sekä prosessin etenemisestä ja voi
        siirtyä lomakkeen sivujen välillä. Vaiheille voi asettaa alavaiheita,
        mutta alatasoille ei voi enää asettaa omia alatasoja.
      </Paragraph>
      <Paragraph>
        Yksittäinen vaihe on mahdollista merkitä laajennettavaksi käyttämällä{' '}
        <Code>isCollapsible</Code>-proppia. Alatason vaiheiden näkymistä
        kontrolloidaan <Code>isOpen</Code>-propilla. Painettaessa laajennus
        painiketta, komponentti kutsuu sille asetettua{' '}
        <Code>onClickExpand</Code>-handler funktiota.
      </Paragraph>
      <Paragraph>
        On suositeltavaa toteuttaa laajennettavan vaiheen tila siten, että
        valittaessa ylätason vaihe, jolla on alatasoja, alatasot näytetään.
        Toisin sanoen valinnan lisäksi asetetaan vaiheelle{' '}
        <Code>isOpen=true</Code>
      </Paragraph>
      <Playground example={SideStepsExample} />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että otsikot ja alaotsikot eivät ole keskenään samoja, sillä
          käyttäjä näkee niistä aina vain yhden kerrallaan.
        </ListItem>
        <ListItem>
          Varmista, että tietoa ei välitetä vain värin avulla: esimerkiksi
          virheen pitää käydä ilmi myös ikonin avulla.
        </ListItem>
        <ListItem>
          Huomioi sidestepperin paikka sivulla: komponentti pitää käydä läpi
          ennen varsinaiseen sivuun liikkumista. Anna käyttäjälle mahdollisuus
          sivuuttaa komponentti myöhemmin.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
