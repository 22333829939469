import React, { useState } from 'react'

import { Columns, SideStep, SideSteps } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const SideStepsExample = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [expanded, setExpanded] = useState<number[]>([])

  return (
    <Columns
      layout={'sidebar'}
      sidebar={
        <SideSteps label="Label">
          <SideStep
            active={activeStep === 0}
            as={LinkMock}
            onClick={() => setActiveStep(0)}
            subSteps={
              <>
                <SideStep
                  active={activeStep === 1}
                  as={LinkMock}
                  onClick={() => setActiveStep(1)}
                >
                  Alatason vaihe
                </SideStep>
                <SideStep
                  active={activeStep === 2}
                  as={LinkMock}
                  status="complete"
                  onClick={() => setActiveStep(2)}
                >
                  Complete
                </SideStep>
                <SideStep
                  active={activeStep === 3}
                  as={LinkMock}
                  status="error"
                  onClick={() => setActiveStep(3)}
                >
                  Error
                </SideStep>
                <SideStep disabled>Disabled</SideStep>
                <SideStep
                  disabled
                  status="complete"
                >
                  Disabled
                </SideStep>
              </>
            }
          >
            Ylätason vaihe
          </SideStep>
          <SideStep
            active={activeStep === 4}
            as={LinkMock}
            isCollapsible
            isOpen={expanded.includes(4)}
            onClick={() => {
              setActiveStep(4)
              setExpanded([4])
            }}
            onClickExpand={() => {
              expanded.includes(4) ? setExpanded([]) : setExpanded([4])
            }}
            subSteps={
              <>
                <SideStep
                  active={activeStep === 5}
                  as={LinkMock}
                  onClick={() => setActiveStep(5)}
                >
                  Default
                </SideStep>
                <SideStep
                  active={activeStep === 6}
                  as={LinkMock}
                  status="complete"
                  onClick={() => setActiveStep(6)}
                >
                  Complete
                </SideStep>
                <SideStep
                  active={activeStep === 7}
                  as={LinkMock}
                  status="error"
                  onClick={() => setActiveStep(7)}
                >
                  Error
                </SideStep>
              </>
            }
          >
            Ylätason vaihe, jolla collapsible
          </SideStep>
          <SideStep
            active={activeStep === 8}
            as={LinkMock}
            status="complete"
            onClick={() => setActiveStep(8)}
          >
            Complete
          </SideStep>
          <SideStep
            active={activeStep === 9}
            as={LinkMock}
            status="error"
            onClick={() => setActiveStep(9)}
          >
            Error
          </SideStep>
          <SideStep disabled>Disabled</SideStep>
          <SideStep
            disabled
            status="complete"
          >
            Disabled
          </SideStep>
        </SideSteps>
      }
    >
      <div />
    </Columns>
  )
}

export { SideStepsExample }
